<template>
  <div class="index" v-loading="pageLoading">
    <div class="content">
      <crumbs-custom :arr="TabBar" />
      <div class="cont">
        <div class="txt-center font-weight typeTitle">{{cateInfo.name}}</div>
        <div class="txt-center font-weight bref m-t-15 m-b-15">{{cateInfo.bref}}</div>
        <div class="box">
          <div class="box-child cursor-pointer" v-for="(item, index) in dataList" :key="index"  @click="$router.push({ path: `/serviceShow/`+item.id+'/'+cateInfo.name+'/'+ cid})">
            <div class="flex-col txt-center">
              <div class="thumb txt-center">
                <img class="" :src="item.basic_logo" alt="">
              </div>
              <div class="title m-t-15">{{item.basic_com_name}}</div>
            </div>
          </div>
        </div>
        <div class="flex-row m-t-15 txt-center">
          <div class="flex-cell"></div>
          <div class="">
              <pagination
                :total="total"
                :curPage="pageParams.page"
                :perPage="pageParams.size"
                @current-change="currentChange"
              />
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { requestPost, requestGet } from "@/api/home/index";
import { setStore, getStore } from '@/utils/auth'
export default {
  name: "Home",
  props: {},
  data() {
    return {
      pageLoading: false,
      pageParams: {
        page: 1,
        size: 9
      },
      total: 0,
      dataList: [],
      cateInfo: {},
      TabBar: [],
      cid: this.$route.params.id
    };
  },
  mounted() {
    l_loadData(this)
  },
  methods: {
    // @@ 改变当前页事件
    currentChange(val) {
      this.pageParams.page = val;
      l_loadData(this);
    },
  },
};

// 加载数据
function l_loadData(pg){
  pg.pageLoading = true;
  requestGet("api/company/cates/"+pg.$route.params.id, pg.pageParams)
    .then((res) => {
      pg.dataList = res.pageData.data || []
      pg.total = res.pageData.count
      pg.cateInfo = res.cateInfo
      pg.pageLoading = false;
      // var arr = pg.$store.state.crumbsCustom || []
      // if(arr.length<=0){
      //   arr = getStore('customArr')
      // }
      // if(arr.length>1){
      //   arr[1]={title: pg.cateInfo.name, router: '/serviceLists/'+pg.$route.params.id}
      // } else{
      //   arr.push({title: pg.cateInfo.name, router: '/serviceLists/'+pg.$route.params.id})
      // }
      // if(arr.length>2){
      //   arr.pop()
      // }
      pg.TabBar = [{router: '/serviceType', title: '家政服务类型'}, {router: '/serviceLists/'+pg.$route.params.id, title: pg.cateInfo.name}]
      // setStore('customArr', arr)
      // console.log(arr)
      // pg.$store.commit("CRUMBS_CUSTOM", arr);
    })
    .catch((error) => {
      pg.$message.error(error);
      pg.pageLoading = false;
    });
}

</script>
<style lang="scss" scoped>
  .content{
    width: 1200px;
    margin: 0 auto;
    .cont{
      background: #fff;
    }
    .typeTitle{
      font-size: 26px;
      padding-top: 30px;
    }
    .bref{
      font-size: 16px;
      color: #666;
    }
    .box{
      padding: 30px;
      .box-child{
        width: 360px;
        height: 230px;
        display: inline-block;
        vertical-align: top;
        margin-bottom: 30px;
        border-radius: 10px;
        box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
        .flex-col{
          width: 100%;
          height: 100%;
          .title{
            color: #333;
            font-size: 16px;
          }
          .thumb{
            height: 70%;
            width: 150px;
            img{
              width: 100%;
              max-height: 100%;
            }
          }
        }
      }
      .box-child:nth-child(3n+2){
        margin: 0 30px;
        margin-bottom: 30px;
      }
    }
  }
</style>
